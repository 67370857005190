import { lazy } from 'react'

const AuthTokenScreen = lazy(()=> import('screens/main/auth_tokens/AuthTokenScreen'))
const AuthRedirectScreen = lazy(()=> import('screens/main/AuthRedirectScreen'))
const BarcodeScannerScreen = lazy(()=> import( 'screens/main/BarcodeScannerScreen'))
const BookingCompleteScreen = lazy(()=> import( 'screens/main/bookings/BookingCompleteScreen'))
const BookingScreen = lazy(()=> import( 'screens/main/bookings/BookingScreen'))
const BookingsHistoryScreen = lazy(()=> import( 'screens/main/bookings/BookingsHistoryScreen'))
const BookingsSearchScreen = lazy(()=> import( 'screens/main/bookings/BookingsSearchScreen'))
const BookingsTabNavigator = lazy(()=> import( 'navigation/BookingsTabNavigator'))
const CSVImportEditScreen = lazy(()=> import( 'screens/main/goods/CSVImportEditScreen'))
const CSVImportScreen = lazy(()=> import( 'screens/main/goods/CSVImportScreen'))
const CommerceLawScreen = lazy(()=> import( 'screens/main/CommerceLawScreen'))
const EditGoodScreen = lazy(()=> import( 'screens/main/goods/EditGoodScreen'))
const EditGroupFoldersScreen = lazy(()=> import( 'screens/main/groups/EditGroupFoldersScreen'))
const EditGroupMembersScreen = lazy(()=> import('screens/main/groups/EditGroupMembersScreen'))
const EditGroupScreen = lazy(()=> import( 'screens/main/groups/EditGroupScreen'))
const EditGroupSignUpScreen = lazy(()=> import( 'screens/main/groups/EditGroupSignUpScreen'))
const EditUserManagersScreen = lazy(()=> import('screens/main/users/managers/EditScreen'))
const ExportGoodsQRCodeScreen = lazy(()=> import( 'screens/main/groups/ExportGoodsQRCodeScreen'))
const ExportGroupAuthTokensScreen = lazy(()=> import('screens/main/groups/ExportGroupAuthTokensScreen'))
const GoodBookingsHistoryScreen = lazy(()=> import( 'screens/main/goods/GoodBookingsHistoryScreen'))
const GoodBookingsScreen = lazy(()=> import( 'screens/main/goods/GoodBookingsScreen'))
const GoodScreen = lazy(()=> import( 'screens/main/goods/GoodScreen'))
const GoodsImportSelectScreen = lazy(()=> import( 'screens/main/goods/GoodsImportSelectScreen'))
const GoodReviewsScreen = lazy(()=> import( 'screens/main/goods/ReviewsScreen'))
const GoodCommentsScreen = lazy(()=> import( 'screens/main/goods/CommentsScreen'))
const GoodTransferScreen = lazy(()=> import( 'screens/main/goods/TransferScreen'))
const GroupAuthTokensScreen = lazy(()=> import( 'screens/main/groups/GroupAuthTokensScreen'))
const GroupAuthTokensSpreadsheetImportScreen = lazy(()=> import( 'screens/main/groups/AuthTokensSpreadsheetImportScreen'))
const GroupEmailInviteScreen = lazy(()=> import( 'screens/main/groups/EmailInviteScreen'))
const GroupGoodsPickerScreen = lazy(()=> import( 'screens/main/groups/GroupGoodsPickerScreen'))
const GroupNotificationSettingScreen = lazy(()=> import( 'screens/main/groups/GroupNotificationSettingScreen'))
const GroupPostEditScreen = lazy(()=> import( 'screens/main/groups/GroupPostEditScreen'))
const GroupPostsScreen = lazy(()=> import( 'screens/main/groups/GroupPostsScreen'))
const GroupScreen = lazy(()=> import( 'screens/main/groups/GroupScreen'))
const GroupSignUpScreen = lazy(()=> import( 'screens/main/groups/GroupSignUpScreen'))
const GroupTransferScreen = lazy(()=> import( 'screens/main/groups/TransferScreen'))
const HomeScreen = lazy(()=> import( 'screens/main/HomeScreen'))
const ISBNImportScreen = lazy(()=> import( 'screens/main/goods/ISBNImportScreen'))
const NewBookingNowScreen = lazy(()=> import( 'screens/main/bookings/NewBookingNowScreen'))
const NewBookingScreen = lazy(()=> import( 'screens/main/bookings/NewBookingScreen'))
const BulkBookingScreen = lazy(()=> import( 'screens/main/bookings/BulkBookingScreen'))
const NewGoodScreen = lazy(()=> import( 'screens/main/goods/NewGoodScreen'))
const NewGroupMemberScreen = lazy(()=> import( 'screens/main/groups/NewGroupMemberScreen'))
const NewGroupPostScreen = lazy(()=> import( 'screens/main/groups/NewGroupPostScreen'))
const NewGroupScreen = lazy(()=> import( 'screens/main/groups/NewGroupScreen'))
const NotFoundScreen = lazy(()=> import('screens/main/NotFoundScreen'))
const PaymentScreen = lazy(()=> import('screens/main/payments/PaymentScreen'))
const PaymentMethodScreen = lazy(()=> import('screens/main/payments/PaymentMethodScreen'))
const PaymentHistoryScreen = lazy(()=> import('screens/main/payments/HistoryScreen'))
const PrivacyScreen = lazy(()=> import( 'screens/main/PrivacyScreen'))
const QRCodeScannerScreen = lazy(()=> import('screens/main/QRCodeScannerScreen'))
const RedirectHomeScreen = lazy(()=> import('screens/main/RedirectHomeScreen'))
const SearchScreen = lazy(()=> import( 'screens/main/SearchScreen'))
const SearchSuggestScreen = lazy(()=> import( 'screens/main/searches/SuggestScreen'))
const SignInScreen = lazy(()=> import('screens/main/SignInScreen'))
const SignInMethodsScreen = lazy(()=> import('screens/main/SignInMethodsScreen'))
const EmailSignInScreen = lazy(()=> import('screens/main/auth/EmailSignInScreen'))
const LandingScreen = lazy(()=> import('screens/main/lp/LandingScreen'))
const LandingBooksScreen = lazy(()=> import('screens/main/lp/books'))
const LanguagesScreen = lazy(()=> import('screens/main/LanguagesScreen'))
const SignUpScreen = lazy(()=> import('screens/main/lp/SignUpScreen'))
const SupportScreen = lazy(()=> import( 'screens/main/SupportScreen'))
const TermsScreen = lazy(()=> import( 'screens/main/TermsScreen'))
const TutorialScreen = lazy(()=> import( 'screens/main/TutorialScreen'))
const UserDeleteScreen = lazy(()=> import( 'screens/main/users/UserDeleteScreen'))
const UserEditScreen = lazy(()=> import( 'screens/main/users/UserEditScreen'))
const UserGoodsPickerScreen = lazy(()=> import('screens/main/users/UserGoodsPickerScreen'))
const UserGroupsScreen = lazy(()=> import( 'screens/main/groups/UserGroupsScreen'))
const UserReminderSettingScreen = lazy(()=> import('screens/main/users/UserReminderSettingScreen'))
const UserNotificationSettingScreen = lazy(()=> import( 'screens/main/users/UserNotificationSettingScreen'))
const UserScreen = lazy(()=> import( 'screens/main/users/UserScreen'))
const UserSignUpScreen = lazy(()=> import('screens/main/UserSignUpScreen'))
const LanguageSettingScreen = lazy(()=> import('screens/main/LanguageSettingScreen'))
const TimeZoneSettingScreen = lazy(()=> import('screens/main/TimeZoneSettingScreen'))
const SharedDeviceSettingScreen = lazy(()=> import('screens/main/SharedDeviceSettingScreen'))
const MembershipSignInScreen = lazy(()=> import('screens/main/MembershipSignInScreen'))
const UpgradeScreen = lazy(()=> import('screens/main/subscriptions/UpgradeScreen'))
const PricingScreen = lazy(()=> import('screens/main/subscriptions/PricingScreen'))

// inside modal
const HelpModalScreen = lazy(()=> import('screens/HelpModalScreen'))
const ShareGoodScreen = lazy(()=> import('screens/ShareGoodScreen'))
const FolderPickerScreen = lazy(()=> import('screens/folders/FolderPickerScreen'))
const PreviewScreen = lazy(()=> import('screens/PreviewScreen'))

export default {
  AuthTokenScreen,
  AuthRedirectScreen,
  BarcodeScannerScreen,
  BookingCompleteScreen,
  BookingScreen,
  BookingsHistoryScreen,
  BookingsSearchScreen,
  BookingsTabNavigator,
  CSVImportScreen,
  CSVImportEditScreen,
  CommerceLawScreen,
  EditGoodScreen,
  EditGroupFoldersScreen,
  EditGroupMembersScreen,
  EditGroupScreen,
  EditGroupSignUpScreen,
  EditUserManagersScreen,
  ExportGoodsQRCodeScreen,
  ExportGroupAuthTokensScreen,
  GoodBookingsHistoryScreen,
  GoodBookingsScreen,
  GoodScreen,
  GoodsImportSelectScreen,
  GoodReviewsScreen,
  GoodCommentsScreen,
  GoodTransferScreen,
  GroupAuthTokensScreen,
  GroupAuthTokensSpreadsheetImportScreen,
  GroupEmailInviteScreen,
  GroupGoodsPickerScreen,
  GroupNotificationSettingScreen,
  GroupPostEditScreen,
  GroupPostsScreen,
  GroupScreen,
  GroupSignUpScreen,
  GroupTransferScreen,
  HomeScreen,
  ISBNImportScreen,
  NewBookingNowScreen,
  NewBookingScreen,
  BulkBookingScreen,
  NewGoodScreen,
  NewGroupMemberScreen,
  NewGroupPostScreen,
  NewGroupScreen,
  NotFoundScreen,
  PaymentScreen,
  PaymentMethodScreen,
  PaymentHistoryScreen,
  PrivacyScreen,
  QRCodeScannerScreen,
  RedirectHomeScreen,
  SearchScreen,
  SearchSuggestScreen,
  SignInScreen,
  SignInMethodsScreen,
  EmailSignInScreen,
  LandingScreen,
  LandingBooksScreen,
  LanguagesScreen,
  SignUpScreen,
  SupportScreen,
  TermsScreen,
  TutorialScreen,
  UserDeleteScreen,
  UserEditScreen,
  UserGoodsPickerScreen,
  UserGroupsScreen,
  UserNotificationSettingScreen,
  UserReminderSettingScreen,
  UserScreen,
  UserSignUpScreen,
  LanguageSettingScreen,
  TimeZoneSettingScreen,
  SharedDeviceSettingScreen,
  MembershipSignInScreen,
  UpgradeScreen,
  PricingScreen,

  // inside modal
  HelpModalScreen,
  ShareGoodScreen,
  FolderPickerScreen,
  PreviewScreen,
}
